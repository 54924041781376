export const getFileSize = (size: number): string => {
  if (Number.isNaN(+size) || !size) {
    return "-";
  }

  if (size < 1024) {
    return `${size} байт`;
  }

  if (size < 1024 ** 2) {
    return `${(size / 1024).toFixed(1)} кб`;
  }

  if (size < 1024 ** 3) {
    return `${(size / 1024 ** 2).toFixed(1)} мб`;
  }

  return `${(size / 1024 ** 3).toFixed(1)} гб`;
};
