









































































import Table from "@monorepo/uikit/src/components/common/Table.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/inventoryReportView/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/inventoryReportView/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import { module as inventoryReportView } from "@monorepo/reports/src/store/modules/inventoryReportView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/reports/src/views/InventoryReportView/constants/breadcrumbs";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import { convertApiItemToUi } from "@monorepo/reports/src/views/InventoryReportView/utils/convertApiItemToUi";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/inventoryReportView/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/reports/src/views/InventoryReportView/utils/defaultCurrentFilters";
import { fields } from "@monorepo/utils/src/variables/projectsData/inventoryReportView/fields";
import useAsyncExport from "@monorepo/utils/src/composables/useAsyncExport";

const SECTION_NAME = "inventoryReportView";

export default defineComponent({
  name: "inventoryReportView",
  components: {
    TableSettings,
    ExportButton,
    InfiniteScroll,
    Table,
    Filters,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
  },
  data() {
    return {
      breadcrumbs,
      viewTitle,
      viewUniqKey,
      isOpenFilters: false,
      educationIds,
      tableHeaders,
      convertApiItemToUi,
      filtersElements,
      setCurrentFilters,
      autocompleteFiltersKeys: [fields.FILTER_FUND],
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters(SECTION_NAME, ["cells", "openedId", "infiniteId", "data", "totalLength", "isTableLoading"]),
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.REPORT_EXPORT);
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getEventList", "getExportData", "addOpenedId"]),
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    useInitTableStoreModule(root, SECTION_NAME, inventoryReportView as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders);
    useSetStartFilters(root, SECTION_NAME, ["id"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
    });
    useAsyncExport(root, SECTION_NAME);

    return {
      filtersLoaded,
      sectionName,
    };
  },
});
