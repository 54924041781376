import { IReportElement } from "@monorepo/reports/src/views/InventoryReportView/types/reportElement";
import { fields } from "@monorepo/utils/src/variables/projectsData/inventoryReportView/fields";
import { getFileSize } from "@monorepo/utils/src/utils/getFileSize";

type Keys = keyof typeof fields;
type Values = typeof fields[Keys];

export const convertApiItemToUi = (item: IReportElement): Record<Values, string | number> => ({
  [fields.INDEX]: item.index || "",
  [fields.CASE_INDEX]: item.caseIndex || "",
  [fields.CASE_ARCHIVAL_CIPHER]: item.caseArchiveCipher || "",
  [fields.CASE_TITLE]: item.caseHeader || "",
  [fields.DOCUMENTS_COUNT]: getFileSize(+item.totalFilesSize) || "",
  [fields.RECEIVED_PERIOD]: item.years || "",
});
