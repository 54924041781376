export interface IFundsReportsFields {
  INDEX: string;
  CASE_INDEX: string;
  CASE_ARCHIVAL_CIPHER: string;
  CASE_TITLE: string;
  DOCUMENTS_COUNT: string;
  RECEIVED_PERIOD: string;

  FILTER_FUND: string;
}

export const fields: IFundsReportsFields = {
  INDEX: "number",
  CASE_INDEX: "caseIndex",
  CASE_ARCHIVAL_CIPHER: "caseArchivalCipher",
  CASE_TITLE: "caseHeader",
  DOCUMENTS_COUNT: "totalFilesize",
  RECEIVED_PERIOD: "years",

  FILTER_FUND: "fundCode",
};
