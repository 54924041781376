import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/inventoryReportView/fields";

export const tableHeaders = [
  { text: "№ п/п", defaultWidth: 120, value: fields.INDEX, isLink: false, isSorted: false, isHandle: false, id: uuid() },
  {
    text: "Делопроизводственные индексы или номера по старой описи",
    tooltip: "Индекс дела",
    defaultWidth: 260,
    value: fields.CASE_INDEX,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Архивный шифр дела",
    tooltip: "Архивный шифр дела",
    defaultWidth: 320,
    value: fields.CASE_ARCHIVAL_CIPHER,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Заголовок дела",
    tooltip: "Заголовок дела",
    isLink: false,
    defaultWidth: 200,
    value: fields.CASE_TITLE,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Крайние даты",
    tooltip: "Первая и последняя дата поступления ЭАД",
    defaultWidth: 200,
    value: fields.RECEIVED_PERIOD,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Объём",
    tooltip: "Количество ЭАД, поступивших на хранение",
    defaultWidth: 200,
    value: fields.DOCUMENTS_COUNT,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
];
