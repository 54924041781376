import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertAutocompleteItemToApi } from "@monorepo/utils/src/api/convertFiltersToApi";
import { fields } from "@monorepo/utils/src/variables/projectsData/inventoryReportView/fields";

export const convertFiltersCustomToApi = (
  resultFilters: ITableFiltersObject & {
    fieldFilters: {
      status?: string;
    };
  }
): void => {
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_FUND, `fieldFilters.${fields.FILTER_FUND}.code`);
};
