import { fields } from "@monorepo/utils/src/variables/projectsData/inventoryReportView/fields";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export const filtersElements = [
  {
    value: fields.FILTER_FUND,
    title: "Фонд",
    type: "autocomplete",
    showSelectedKey: "code",
    autocompleteActionName: "getAutocompleteFilterByCode",
    className: "mt-4 fund-filter",
    width: 300,
    educationId: educationIds.listViewSettings,
  },
];
